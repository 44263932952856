import { TableColumn } from "../../components/info-table/info-table";
import {
    tableCellTimeStamp,
    commaString,
    statusFormat,
    tickOnTrueValue,
} from "../../modules/format-table-cells/format-table-cells";

export const importColumns: TableColumn[] = [
    {
        label: "Import ID",
        key: "importId",
        align: "left",
        path: "import/",
        linkKey: "importId",
    },
    { label: "Import type", key: "importType", align: "left", truncate: 50, format: commaString },
    { label: "Upload date", key: "createdAt", align: "left", format: tableCellTimeStamp },
    { label: "Upload status", key: "complete", align: "left", format: statusFormat },
    { label: "Has errors", key: "hasErrors", align: "left", format: tickOnTrueValue },
];
