import React, { useState, useEffect, useCallback, useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { WellPlates, PlateItemKey } from "../../hooks/useWellPlate";
import { SetPatchFunction } from "../../hooks/useDetailForm";
import { numberToPlatePosition } from "../../modules/numberToCoords";
import { DartOrderDetailSpecies, DartOrderDetailSample } from "../../types";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DropdownButton from "../button/dropdown-button";
import { debounce } from "lodash";
import { useWellPlateStyles } from "./well-plate-styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    addSpecies,
    selectItemAtIndex,
    removeSample,
    setSampleIsInPlate,
    keyToValue,
} from "./well-plate-helpers";
import { samplesToCsvUrl } from "./samples-to-csv";
import { wellPlateTableCols, PlateOptions } from "./well-plate-config";
import ActionButton from "../button/action-button";
import { useTheme } from "@material-ui/core/styles";

type WellPlateTableProps = {
    id: string; // Service number
    wellPlates: WellPlates;
    options: PlateOptions;
    setPatchValue: (key: string, value: any, id: string) => void;
    samples: DartOrderDetailSample[];
    species: DartOrderDetailSpecies[];
};

// Table of samples that are in well plate
function WellPlateTable({
    id,
    wellPlates,
    options,
    species,
    samples,
    setPatchValue,
}: WellPlateTableProps) {
    const classes = useWellPlateStyles();
    const theme = useTheme();

    const [inputValue, setInputValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [[rowsWithErrors], setRowsWithErrors] = useState([new Set<number>()]);

    useEffect(() => {
        setInputValue("");
        setRowsWithErrors([new Set<number>()]); // For now, only one selected row can have an error
    }, [wellPlates.selectedItem]);

    const tableSize = options.numCols * options.numRows;
    const numRows = tableSize; // Shows one plate at a time
    const tableIndexOffset = tableSize * wellPlates.getCurrentPlateIndex();
    const selectedIndex = wellPlates.selectedItem;

    // Make sure plate/row/column cells are filled even if there's no sample
    function formatEmptyCell(
        i: number,
        key: PlateItemKey,
        loading: boolean,
        isSelectedRow: boolean,
        options: PlateOptions
    ): JSX.Element {
        const { row, column, plateNumber } = numberToPlatePosition(
            i,
            options.numRows,
            options.numCols,
            wellPlates.getNumPlates()
        );
        switch (key) {
            case "plateNumber":
                return <span>{plateNumber}</span>;
            case "row":
                return <span>{row}</span>;
            case "column":
                return <span>{column}</span>;
            case "inPlate":
                return loading && isSelectedRow ? (
                    <div style={{ width: 15, paddingLeft: 5 }}>
                        <CircularProgress color={"secondary"} style={{ height: 19, width: 19 }} />
                    </div>
                ) : (
                    <span></span>
                );
            default:
                return <span>-</span>;
        }
    }

    function onSetIsInPlate(
        sample: DartOrderDetailSample,
        isInPlate: boolean,
        allSamples: DartOrderDetailSample[],
        allSpecies: DartOrderDetailSpecies[]
    ): void {
        const { samples, species } = setSampleIsInPlate(sample, isInPlate, allSamples, allSpecies);
        setPatchValue(
            "sampleAndSpecies",
            {
                samples: samples,
                species: species,
            },
            id
        );
    }

    function onDeleteSample(
        sampleToDelete: DartOrderDetailSample,
        allSamples: DartOrderDetailSample[],
        allSpecies: DartOrderDetailSpecies[]
    ): void {
        const { samples, species } = removeSample(sampleToDelete, allSamples, allSpecies);
        setPatchValue(
            "sampleAndSpecies",
            {
                samples: samples,
                species: species,
            },
            id
        );
    }

    function keyToCellContents(
        key: PlateItemKey,
        sample: DartOrderDetailSample,
        samples: DartOrderDetailSample[],
        species: DartOrderDetailSpecies[],
        color: string
    ): JSX.Element {
        if (key === "inPlate") {
            return (
                <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={sample.inPlate}
                    onChange={() => {
                        onSetIsInPlate(sample, !sample.inPlate, samples, species);
                    }}
                    style={{ color: color, padding: 3 }}
                />
            );
        }

        const innerText =
            key === "nswNumber" ? sample.displayNswNumber : keyToValue(key, sample, species);

        // If the key is nswNumber, we want to link to the sample
        if (key === "nswNumber") {
            return (
                <a
                    href={`/dashboard/samples/tissue/${sample.nswNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {innerText}
                </a>
            );
        }

        return <span>{innerText}</span>;
    }

    /**
     * Handles the logic for whether a sample is a duplicate or not.
     *
     * This function checks if a sample with the given NSW number already exists.
     * If it does, it calculates the next duplicate number and prompts the user
     * for confirmation to add the duplicate.
     */
    const handleDuplicateSample = (
        nswNumber: string,
        existingSamples: DartOrderDetailSample[]
    ): number | null => {
        const duplicateSamples = existingSamples.filter(s => s.nswNumber === nswNumber);
        if (duplicateSamples.length === 0) return 0;

        // We could've used length of duplicateSamples, but this is more robust
        // as it handles the case where a sample has been deleted and recreated.
        const latestDuplicate = duplicateSamples.reduce((prev, current) =>
            prev.duplicateNumber > current.duplicateNumber ? prev : current
        );
        const duplicateNumber = (latestDuplicate.duplicateNumber || 0) + 1;
        const platePosition = `on plate ${latestDuplicate.plateNumber}, row ${latestDuplicate.row}, column ${latestDuplicate.column}`;

        const confirmAdd = window.confirm(
            `Sample ${nswNumber} is already in this order (${platePosition}).\n` +
                `Do you want to add it as a technical replicate?`
        );

        // If the user doesn't want to add a duplicate, return null
        if (!confirmAdd) return null;

        return duplicateNumber;
    };

    const addSampleToPlate = (
        nswNumber: string,
        index: number,
        existingSamples: DartOrderDetailSample[],
        species: DartOrderDetailSpecies[],
        options: PlateOptions
    ): void => {
        const duplicateNumber = handleDuplicateSample(nswNumber, existingSamples);
        // Exit early if the user doesn't want to add a duplicate
        if (duplicateNumber === null) {
            setInputValue("");
            setLoading(false);
            return;
        }

        wellPlates.findSampleByNswNumber(nswNumber, index, options).then(resp => {
            setLoading(false);
            if (!resp) {
                // API call did not find the NSW Number
                setRowsWithErrors([rowsWithErrors.add(index)]);
                return;
            }

            const displayNswNumber =
                duplicateNumber > 0 ? `${nswNumber}_${duplicateNumber}` : nswNumber;
            const newSample = {
                ...resp.sample,
                displayNswNumber: displayNswNumber,
                duplicateNumber: duplicateNumber,
            };
            setPatchValue(
                "sampleAndSpecies",
                {
                    samples: [...existingSamples, newSample],
                    species: addSpecies(resp.species, species),
                },
                id
            );
            // Go to the next cell
            wellPlates.setSelectedItem(index + 1);
        });
    };

    // 'Add sample to plate' is called based on scanner entry, so need to debounce
    // useCallback also needs a dependency array so it updates when these change
    const debounceAddSample = useCallback(debounce(addSampleToPlate, 500), [
        samples,
        species,
        setPatchValue,
    ]);

    // Generate CSV on frontend that can be downloaded
    const downloadOptions = useMemo(() => {
        return [
            {
                label: "CSV",
                downloadHref: samplesToCsvUrl(samples, species, options.numRows, options.numCols),
                downloadFilename: `${id}-${new Date().toISOString().slice(0, 10)}.csv`,
            },
        ];
    }, [samples, species, options, id]);

    const getRowBgColor = (
        rowIsSelected: boolean,
        rowHasError: boolean,
        rowIsDisabled: boolean
    ): string => {
        if (rowHasError) {
            return theme.palette.error.light;
        } else if (rowIsDisabled) {
            return theme.palette.neutral.dark;
        } else if (rowIsSelected) {
            return theme.palette.secondary.light;
        } else {
            return "white";
        }
    };

    // Well plate table shows only one plate at a time
    return (
        <div>
            <TableContainer className={classes.container}>
                <Table size="small" stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            {wellPlateTableCols.map((col, i) => {
                                return (
                                    <TableCell key={i} className={classes.headerCell}>
                                        {col.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(numRows)].map((_, i) => {
                            const index = i + tableIndexOffset;
                            const sampleDetails = selectItemAtIndex(
                                index,
                                samples,
                                options,
                                wellPlates.getNumPlates()
                            );
                            const rowHasError = rowsWithErrors.has(index);
                            const rowIsSelected = index === selectedIndex;
                            const rowIsDisabled = options.unusedIndexes.includes(i);

                            // If the row has something in it
                            if (sampleDetails) {
                                return (
                                    <TableRow
                                        key={`row-${i}`}
                                        onClick={() => {
                                            wellPlates.setSelectedItem(index);
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor: getRowBgColor(
                                                rowIsSelected,
                                                rowHasError,
                                                rowIsDisabled
                                            ),
                                        }}
                                    >
                                        {wellPlateTableCols.map((col, i) => {
                                            return (
                                                <TableCell key={i}>
                                                    {keyToCellContents(
                                                        col.key,
                                                        sampleDetails,
                                                        samples,
                                                        species,
                                                        wellPlates.getColorForSpecies(
                                                            sampleDetails.taxonKey
                                                        )
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }
                            // If the row has nothing in it
                            else {
                                return (
                                    <TableRow
                                        key={`row-${index}`}
                                        title={
                                            rowHasError ? "NSW number not found in database" : ""
                                        }
                                        onClick={() => {
                                            !rowIsDisabled && wellPlates.setSelectedItem(index);
                                        }}
                                        style={{
                                            cursor: rowIsDisabled ? "not-allowed" : "pointer",
                                            backgroundColor: getRowBgColor(
                                                rowIsSelected,
                                                rowHasError,
                                                rowIsDisabled
                                            ),
                                        }}
                                    >
                                        {wellPlateTableCols.map((col, j) => {
                                            return (
                                                <TableCell key={j}>
                                                    {rowIsSelected && col.key === "nswNumber" ? (
                                                        <input
                                                            id="genotype-input"
                                                            autoFocus
                                                            value={inputValue}
                                                            className={classes.genotypeInput}
                                                            onChange={e => {
                                                                const val = e.target.value;
                                                                setLoading(true);
                                                                setInputValue(val);
                                                                debounceAddSample(
                                                                    val,
                                                                    index,
                                                                    samples,
                                                                    species,
                                                                    options
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>
                                                            {formatEmptyCell(
                                                                index,
                                                                col.key,
                                                                loading,
                                                                rowIsSelected,
                                                                options
                                                            )}
                                                        </span>
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div
                style={{
                    marginTop: 20,
                    width: "100%",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                {selectItemAtIndex(selectedIndex, samples, options, wellPlates.getNumPlates()) && (
                    <ActionButton
                        label="Remove selected"
                        endIcon={<DeleteIcon />}
                        severity="danger"
                        onClick={() => {
                            const sampleToDelete = selectItemAtIndex(
                                selectedIndex,
                                samples,
                                options,
                                wellPlates.getNumPlates()
                            );
                            if (!sampleToDelete) {
                                alert("Error, that NSW Number was not found");
                            } else {
                                if (
                                    window.confirm(
                                        `Are you sure you want to delete ${sampleToDelete.displayNswNumber}?`
                                    )
                                ) {
                                    onDeleteSample(sampleToDelete, samples, species);
                                }
                            }
                        }}
                    />
                )}
                <div style={{ marginLeft: 20 }}>
                    <DropdownButton
                        buttonLabel={"Export"}
                        buttonOptions={downloadOptions}
                    ></DropdownButton>
                </div>
            </div>
        </div>
    );
}

export default WellPlateTable;
