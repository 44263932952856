import { DetailPageSection } from "../../types";
import { dartServices } from "../../modules/listItemsFromEnums";
import { ensureUniqUpdatedId } from "../../modules/validation/checkValidId";
import { formatEmptyString } from "../../modules/formatters";

export const orderForm: DetailPageSection[] = [
    {
        sectionTitle: "Create new order",
        fields: [
            {
                label: "Service number",
                key: "serviceNumber",
                isReadOnly: false,
                inputType: "charfield",
                validate: (newId, existingId) =>
                    ensureUniqUpdatedId("dart-order", newId, existingId),
            },
            {
                label: "Service",
                key: "service",
                isReadOnly: false,
                inputType: "filter",
                options: dartServices,
            },
            {
                label: "Project",
                key: "projectKey",
                isReadOnly: false,
                inputType: "autocomplete",
                optionsKey: "projectList",
            },
            { label: "Date sent", key: "dateSent", isReadOnly: false, inputType: "datepicker" },
            {
                label: "Date received",
                key: "dateReceived",
                isReadOnly: false,
                inputType: "datepicker",
            },
            {
                label: "Notes",
                key: "notes",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Created by",
                key: "createdBy",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatEmptyString,
            },
            {
                label: "Created at",
                key: "createdAt",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatEmptyString,
            },
            {
                label: "Last updated by",
                key: "lastUpdatedBy",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatEmptyString,
            },
            {
                label: "Last updated at",
                key: "lastUpdatedAt",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatEmptyString,
            },
        ],
    },
];
