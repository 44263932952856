import { PaginatedResultSet } from ".";

export enum DArTService {
    SEQUENCING = "Sequencing",
    DNA_AND_SEQUENCING = "DNA & Sequencing",
    OPTIMISATION = "Optimisation",
}

export enum DArTAnalysisStatus {
    DONE = "done",
    IN_PROGRESS = "in progress",
}

// --------------

export type DartOrderListItem = {
    serviceNumber: string;
    dateSent: string | null;
    service: DArTService | null;
    dateReceived: string | null;
    projectKey: string;
    orderSampleCount: number;
    finalOrderCount: number;
    species: DartOrderListItemSpecies[];
};

export type DartOrderListResponse = PaginatedResultSet<DartOrderListItem>;

export type DartOrderListItemSpecies = {
    acceptedName: string;
    sampleCount: number;
    isFinalOrder: boolean;
    analysisStatus: DArTAnalysisStatus | null;
};

// --------------

export type DartOrderPost = {
    serviceNumber: string;
};

// --------------
export type DartOrderDetailSample = {
    nswNumber: string;
    displayNswNumber: string;
    duplicateNumber: number;
    taxonKey: string;
    plateNumber: number;
    row: string;
    column: number;
    inPlate: boolean;
};

/**
 * Join with samples to get number etc.
 */
export type DartOrderDetailSpecies = {
    taxonKey: string;
    genus: string;
    species: string;
    acceptedName: string;
    analysisStatus: DArTAnalysisStatus | null;
    isFinalOrder: boolean;
};

export type DartOrderDetail = {
    serviceNumber: string;
    service: DArTService | null;
    projectKey: string | null;
    dateSent: string | null;
    dateReceived: string | null;
    notes: string | null;
    createdBy: string | null;
    lastUpdatedBy: string | null;
    createdAt: string | null;
    lastUpdatedAt: string | null;
    species: DartOrderDetailSpecies[];
    /**
     * Samples that make up the well plate
     */
    samples: DartOrderDetailSample[];
};

// --------------

/**
 * Different from other PATCH's in that we have fields that MUST be sent, or they will be deleted.
 */

export type DartOrderPatchSpecies = {
    taxonKey: string;
    isFinalOrder: boolean;
    analysisStatus: DArTAnalysisStatus | null;
};
export type DartOrderPatchSample = {
    nswNumber: string;
    displayNswNumber: string;
    duplicateNumber: number;
    taxonKey: string;
    plateNumber: number;
    row: string;
    column: number;
    inPlate: boolean;
};
export type DartOrderPatch = {
    serviceNumber?: string;
    service?: DArTService | null;
    projectKey?: string | null;
    dateSent?: string | null;
    dateReceived?: string | null;
    notes?: string | null;
    /**
     * REQUIRED for patch.
     */
    species: DartOrderPatchSpecies[];
    /**
     * REQUIRED for patch
     * Samples that make up the well plate
     */
    samples: DartOrderPatchSample[];
};
