import { DetailPageSection } from "../../types";
import {
    formatStatus,
    formatTzTimestamp,
    formatBoolean,
    formatEmptyString,
} from "../../modules/formatters";

export const importForm: DetailPageSection[] = [
    {
        sectionTitle: "Import details",
        fields: [
            { label: "Import type", key: "importType", isReadOnly: true, inputType: "textfield" },
            {
                label: "Upload date",
                key: "createdAt",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatTzTimestamp,
            },
            {
                label: "Upload status",
                key: "complete",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatStatus,
            },
            {
                label: "Has errors",
                key: "hasErrors",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatBoolean,
            },
            {
                label: "Import notes (admin only)",
                key: "importNotes",
                inputType: "textfield",
                isReadOnly: true,
                formatReadOnlyValue: formatEmptyString,
            },
        ],
    },
];
